<div class="container-fluid w-100 d-flex justify-content-center mt-5">
  <div class="row d-flex justify-content-center">
    <div class="col-md-6 ">
      <app-main-card
        [icon]="'bi-gem'"
        cardTitle="Work in progress page"
        description="If you are seeing this the page is working but the content is not implemented yet"></app-main-card>
    </div>
  </div>
</div>
