<div style="display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: stretch;
    align-content: stretch;
    min-height: 100vH;
overflow: hidden;">
  <div class="container mt-5">
    <div class="row justify-content-center mt-5">
      <a routerLink="/" role="button" class="text-center mt-5">
        <img alt="logo stimulus" aria-label="label" role="img" src="/assets/LogoSCS.svg"
             style="max-width: 300px">
      </a>
      <p class="text-center mt-3" style="font-size: 40px; color: var(--white)">404 NOT FOUND</p>
    </div>
  </div>
</div>
