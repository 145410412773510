import {Component} from '@angular/core';

@Component({
  selector: 'app-mvp-component',
  templateUrl: './mvp-component.component.html',
  styleUrls: ['./mvp-component.component.scss']
})
export class MvpComponentComponent {

}
