import {Injectable} from '@angular/core';
import {UserPreferenceStorageKeyEnum} from "../interfaces";

@Injectable({
  providedIn: 'root'
})
export class UserPreferenceService<T> {

  constructor() { }

  public save(storageKey: UserPreferenceStorageKeyEnum, data: Partial<T>): void {
    const currentValue = JSON.parse(localStorage.getItem(storageKey) || '{}')
    sessionStorage.setItem(storageKey, JSON.stringify({...currentValue, ...data}))
  }
  public get(storageKey: UserPreferenceStorageKeyEnum): Partial<T> | null {
    return JSON.parse(sessionStorage.getItem(storageKey) || 'null')
  }
  public remove(storageKey: UserPreferenceStorageKeyEnum):void {
    return sessionStorage.removeItem(storageKey)
  }
}
