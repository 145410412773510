export interface ClientDto {
  ref: string,
  name: string;
  parentClientRef: string;
  parentClientName?: string;
}

export interface GenericDtoMetaResponse {
  count: number,
  total: number,
  pages: number,
  currentPage: number,
  prevPage: null | number,
  nextPage: number,
}

export interface GenericDtoResponse {
  data: any
}

export interface ClientDtoResponse extends GenericDtoResponse {
  data: ClientDto[]
}

export interface MenuItem {
  label: string
  url: string;
  class: string;
  icon: string;
  visible: boolean;
  position: number
}

export interface UserMinimumInfo {
  ref: string,
  firstName: string,
  lastName: string
}

export enum UserPreferenceStorageKeyEnum {
  interviewSheetListFilters = 'interviewSheetListFilters'
}

export enum UserSpaces {
  Operator = 'operator-area',
  Beneficiary = 'beneficiary-area',
  //psy, social worker, qwl officer
  Speaker = 'speaker-area',
  Dev = 'developer-area',
  BeneficiaryCompany = 'company-area',
}

export enum Languages {
  En = 'en',
  Fr = 'fr',
  It = 'it',
  Es = 'es',
}

export interface AccountCreateDTO {
  clientRef: string;
  email: string;
  password: string;
  repeatedPassword: string;
}

export enum UserRole {
  Psychologist = 'psychologist',
  Social_worker = 'social_worker',
  QWL_officer = 'qwl_officer',
  Beneficiary = 'beneficiary',
  Admin = 'admin',
  Company_employee = 'company_employee',
  Company_manager = 'company_manager',
  BusinessDeveloper = 'business_developer',
  ClientManager = 'client_manager',
  Daemon = 'daemon',
}

export interface UserDto {
  active: boolean;
  application: {
    ref: string;
  },
  connectionCount: number;
  connectionDate: null | string;
  ref: string;
  role: UserRole;
}
export interface UserExtendedDto extends UserDto{
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  username: string;
}
export interface UserDetailClient {
  active: boolean;
  businessDeveloper: null | string;
  chatWidget: null | string;
  connectionCount: number;
  connectionDate: string;
  contractFile: null | string;
  contractFileQwl: null | string;
  contractFileSw: null | string;
  description: null | string;
  dnis: null | string;
  eap: boolean;
  endDate: string;
  faceToFaceAppointmentsNo: number;
  hasAssignee: boolean;
  hasCallback: boolean;
  hasChat: boolean;
  hasCoaching: boolean;
  hasEval: boolean;
  hasEverydayPracticalLife: boolean;
  hasForm: boolean;
  hasInformativeContent: boolean;
  hasLaborLawSupport: boolean;
  hasLegalInfo: boolean;
  hasOnsite: boolean;
  hasPhoneCall: boolean;
  hasPrevCare: boolean;
  hasSatisfactionStats: boolean;
  hasSpecificPsy: boolean;
  hasSpecificSw: boolean;
  hasVideoCall: boolean;
  hasWebinar: boolean;
  hideSatisfactionQuestion: boolean;
  hideUserHomeMsg: boolean;
  name: string;
  onsiteInfo: null | string;
  origin: null | string;
  phoneAppointmentsNo: number;
  phoneEmployee: null | string;
  phoneItSupport: null | string;
  phoneManager: null | string;
  phoneQwl: null | string;
  provider: null | string;
  psychologicalSupportEnabled: boolean;
  ref: string;
  referentOperator: null | string;
  reportingFrequency: null | string;
  satisfactionQuestion: null | string;
  serviceAccess: null | string;
  services: any[]
  socialSupportEnabled: null | string;
  specificQuestions: boolean;
  startDate: string;
  textChat: null | string;
  textDedicatedNumber: null | string;
  textWelcome: null | string;
  userHomeImg: null | string;
}
export interface UserDetailLanguage {
  code: string;
  locale: string;
  name: string;
  ref: string;
}
export interface User {
  adeliCode: string;
  clients?: UserDetailClient[];
  client?: UserDetailClient;
  connected: boolean;
  extern: boolean;
  hasCoaching: boolean;
  hasForm: boolean;
  hasOnsite: boolean;
  hasPermanence: boolean;
  hasPhoneCall: boolean;
  hasPrevCare: boolean;
  hasVideoCall: boolean;
  intern: boolean;
  languages: UserDetailLanguage[];
  operatorType: UserRole;
  picture: null | string;
  presentation: null | string;
  presentationCoach: null | string;
  ref: string;
  specialties: {
    name: string;
    ref: string;
  }[]
  title: string;
  user: UserExtendedDto
}
export interface UserDetails {
  data: User
}

export interface GenericIdentifierCreateDto {
  identifier: string
}

export interface SearchSelectDTO {
  data: SearchSelectOption[];
}

export interface SearchSelectOption {
  name: string;
  ref: string;
  parentClientRef?: string | null;
}

export enum AvailabilityColors {
  Blue = "#5191cf",
  Red = "#F4244E",
  Purple = "#9459cb",
  Green = "#43733f",
  Orange = "#ff5733"
}

export interface Availability {
  operator: {
    ref: string,
    operatorType: string,
    user: {
        ref: string,
        role: string,
        firstName:string,
        lastName: string,
        active: boolean,
        application: {
            longName: string,
            shortName: string,
            ref: string
        },
        connectionDate: string,
        connectionCount: number
        }
    },
    user: {
        ref: string,
        role: string,
        firstName: string,
        lastName: string,
        active: boolean,
        application: {
            longName: string,
            shortName: string,
            ref: string
        },
        connectionDate: string,
        connectionCount: number
    },
    client: {
        parentClientRef: string,
        ref: string,
        name: string,
        connectionDate: string,
        connectionCount: number
    },
    onSite: any,
    type: string,
    availabilityStart: string,
    isFree: boolean,
    hasConfirmationEmail: boolean,
    beneficiaryPhoneNumber: string,
    beneficiaryInfo: string,
    hasVideoCallDone: boolean,
    ref: string
}

export interface OperatorsAvailabilities {
  data: Availability[];
}

export interface OperatorsFormatedAvailabilities {
  title: string;
  description: string;
  start: string;
  type: string;
  tel_user: string;
  allDay: boolean;
  color: string
  directdeletable: boolean
  id: string;
  free: boolean
}

export interface AddPlanningPayload {
  operatorRef: string;
  clientRef: string | null;
  beneficiaryAccountRef: string | null;
  onSiteRef: string | null;
  type: string | null;
  availabilityStart: string;
  isFree: boolean;
  hasConfirmationEmail: boolean;
  beneficiaryPhoneNumber: string;
  beneficiaryInfo: string;
  hasVideoCallDone: boolean;
}
