import {Injectable, Injector} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {catchError, mergeMap, Observable, throwError} from 'rxjs';
import {MsalService} from "@azure/msal-angular";
import {msalConfig} from "./auth-config";
import {Router} from "@angular/router";
import {environment} from "../environments/environment";

@Injectable()
export class HttpInterceptorInterceptor implements HttpInterceptor {

  constructor(
    private readonly injector: Injector,
    private msalService: MsalService,
    private authService: MsalService,
    private router: Router,
    ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {


    const selectedLanguage = localStorage.getItem('lang') || 'fr';
    if(selectedLanguage) {
      request = request.clone({
        headers: request.headers.set('Accept-Language', selectedLanguage)
      })
    }
    const currentEntity = localStorage.getItem('appId');
    if(currentEntity) {
      request = request.clone({
        headers: request.headers.set('applicationId', currentEntity)
      })
    }
    let account = this.msalService.instance.getActiveAccount();
    if (account) {
      //TODO the MSALInterceptorConfigFactory from app.module does not seem to want to put the idToken, it sets an empty
      // bearer. I saw some discussions on their gitHub page but it seems closed from 2022
      // link here: https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/4716
      // here should be a working sample https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/samples/msal-angular-v3-samples/angular15-sample-app/package.json
      const idTokenLocalStorageKey = `${account.homeAccountId}-${environment.msal.authorityDomain}-idtoken-${msalConfig.auth.clientId}----`
      const localData = localStorage.getItem(idTokenLocalStorageKey) || '';
      request = request.clone({
        headers: request.headers.set('Authorization', `Bearer ${JSON.parse(localData).secret}`)
      })
    }
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if(error.status === 403) {
          this.authService.logout();
          this.authService.loginRedirect();
        }
        if (error.status === 401) {
          return this.authService.acquireTokenSilent({
            scopes: [],
          }).pipe(
            mergeMap((token) => {
              request = request.clone({
                headers: request.headers.set('Authorization', `Bearer ${token.idToken}`)
              })
              return next.handle(request);
            }),
            catchError((error) => {
              console.error('Error acquiring token:', error);
              this.msalService.acquireTokenRedirect({
                scopes: [],
              })
              return throwError(error);
            })
          );
        } else {
          console.error('Error:', error);
          return throwError(error);
        }
      })
    ) as Observable<HttpEvent<any>>;
  }
}

