import {Component, Input, TemplateRef} from '@angular/core';

@Component({
  selector: 'app-main-card',
  templateUrl: './main-card.component.html',
  styleUrls: ['./main-card.component.scss']
})
export class MainCardComponent {
  @Input() public cardTitle: string | undefined;
  @Input() public bodyMargin = true;
  @Input() public isMainCard = true;
  @Input() public description: string | undefined;
  @Input() public underDescription: string | undefined;
  @Input() public icon: string | undefined;
  @Input() public ctaLabel: string | undefined;
  @Input() public ctaPosition: 'inside' | 'outside' = 'outside';
  @Input() public ctaLabelUrl: string | undefined;
  @Input() public cssClass: string = '';
  @Input() public cssBodyClass: string = '';
  @Input() public cardTemplate: TemplateRef<any>;
}
