import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {NotFoundComponent} from "./shared/components/not-found/not-found.component";
import {MsalGuard} from "@azure/msal-angular";
import {UserSpaces} from "./shared/interfaces";
import {LoginClientComponent} from "./auth/login-client/login-client.component";
import {BeneficiaryGuard} from "./shared/guards/beneficiary.guard";
import {MvpComponentComponent} from "./shared/components/mvp-component/mvp-component.component";

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/landing'
  },
  {
    path: 'testing',
    component: MvpComponentComponent
  },
  {
    path: 'auth/login/:clientRole',
    component: LoginClientComponent
  },
  {
    path: UserSpaces.Operator,
    canActivate: [MsalGuard],
    loadChildren: () => import('./operator-space/operator-space.module').then((m) => m.OperatorSpaceModule),
  },
  {
    path: UserSpaces.Beneficiary,
    canActivate: [MsalGuard, BeneficiaryGuard],
    loadChildren: () => import('./beneficiary-space/beneficiary-space.module').then((m) => m.BeneficiarySpaceModule),
  },
  {
    path: 'live-chat',
    canActivate: [MsalGuard],
    loadChildren: () => import('./shared-messages/shared-messages.module').then(m => m.SharedMessagesModule)
  },
  {
    path: 'static',
    loadChildren: () => import('./static-pages/static-pages/static-pages.module').then(m => m.StaticPagesModule)
  },
  {
    path: 'landing',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
