export const environment = {
  apiUrl: 'https://dev-api.stimulus-care-services.com/',
  production: false,
  userLike : {
    en: "466cbcb44b7c4572b6918fcbb4095024f2e1af5fb04640d894e6c711a7d8da89",
    fr: "466cbcb44b7c4572b6918fcbb4095024f2e1af5fb04640d894e6c711a7d8da89",
    es: "bab35d06f2114e1abb9fd290a0878251655165655e8e42569aff54262e678e9b",
    it: "c89e0b1bbbb74284a1f25f7aefa95f37854d4c5b7df44a45b77fb1ce22bd334b",
    test: "367e0e87b78e428783abbd8ad8df7a2eca36b5cb67f24c388317d0c6e1b0498a"
  },
  msal: {
    signUpSignIn: 'B2C_1A_SM_signin_email_operator',
    signUpSignInBeneficiary: 'B2C_1A_SM_signin_email_beneficiary',
    signUpSignInCompany: 'B2C_1A_SM_signin_username_company',
    resetPassword: 'B2C_1A_SM_PasswordReset',
    editProfile: 'B2C_1_ProfileEditing_1',
    authorities: {
      signUpSignIn: {
        authority: 'https://scsb2cpoc.b2clogin.com/scsb2cpoc.onmicrosoft.com/B2C_1A_SM_signin_email_operator',
        authorityBeneficiary: 'https://scsb2cpoc.b2clogin.com/scsb2cpoc.onmicrosoft.com/B2C_1A_SM_signin_email_beneficiary',
        authorityBeneficiaryCompany: 'https://scsb2cpoc.b2clogin.com/scsb2cpoc.onmicrosoft.com/B2C_1A_SM_signin_username_company',
      },
      resetPassword: {
        authority: 'https://scsb2cpoc.b2clogin.com/scsb2cpoc.onmicrosoft.com/B2C_1A_SM_PasswordReset',
      },
      editProfile: {
        authority: 'https://scsb2cpoc.b2clogin.com/scsb2cpoc.onmicrosoft.com/B2C_1_ProfileEditing_1',
      },
    },
    authorityDomain: 'scsb2cpoc.b2clogin.com',
    b2cScopes: [
      "https://scsb2cpoc.onmicrosoft.com/tasks-api/app.read",
      "https://scsb2cpoc.onmicrosoft.com/tasks-api/app.write",
    ],
    clientId: 'b3002b99-8afe-42ae-b8ca-51e910d187a6',
    passwordResetLink: `https://scsb2cpoc.b2clogin.com/scsb2cpoc.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1A_SM_PasswordReset&client_id=b3002b99-8afe-42ae-b8ca-51e910d187a6&nonce=defaultNonce&scope=openid&response_type=id_token&prompt=login`
  }
};
