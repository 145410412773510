<div [class.main_card]="isMainCard" [ngClass]="cssClass" class="card ">
  <div *ngIf="icon" class="d-flex align-items-center justify-content-center position-relative z-index-1">
    <span class="position-absolute top-0 start-50 translate-middle bgwhite round-item-24">
      <i [ngClass]="icon" class="bi yiconcircle sm-cust fs-22 fextralight"></i>
    </span>
  </div>
  <div class="card-body {{cssBodyClass}}">
    <ng-content></ng-content>
    <div *ngIf="!cardTemplate && isMainCard" class="mb-4 mt-5">
      <h2 *ngIf="cardTitle" class="text-uppercase f10 fsemibold fs-21">{{cardTitle}}</h2>
      <p *ngIf="description" class=" f09">{{description}}</p>
      <p *ngIf="underDescription" class="text-uppercase f10 fsemibold fs-10">{{underDescription}}</p>
      <ng-container
        [ngTemplateOutlet]="cardTemplate"></ng-container>

    </div>
    <ng-container
      *ngIf="cardTemplate && !isMainCard"
      [ngTemplateOutlet]="cardTemplate"></ng-container>

    <div *ngIf="ctaLabel && ctaLabelUrl && ctaPosition === 'inside'" class=" ">
      <div class="d-flex align-items-center justify-content-center">
        <a [routerLink]="ctaLabelUrl" class="mb-2 btn btn-secondary bg-dark-blue w-100" role="button">{{ctaLabel}}</a>
      </div>
    </div>
  </div>
</div>
<div *ngIf="ctaLabel && ctaLabelUrl && ctaPosition === 'outside'" class=" ">
  <div class="d-flex align-items-center justify-content-center">
    <a [routerLink]="ctaLabelUrl" class="mb-2 btn btn-outline-secondary" role="button">{{ctaLabel}}</a>
  </div>
</div>
