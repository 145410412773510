import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Title} from "@angular/platform-browser";
import {TranslateService} from "@ngx-translate/core";
import {MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService} from "@azure/msal-angular";
import {
  AccountInfo,
  AuthenticationResult,
  EventMessage,
  EventType,
  InteractionStatus,
  InteractionType,
  PopupRequest,
  RedirectRequest
} from "@azure/msal-browser";
import {filter, Subject, takeUntil} from "rxjs";
import {getEntityLanguage} from "./app.module";
import {UserService} from "./shared/services/user.service";
import {Router} from "@angular/router";
import {UserPreferenceService} from "./shared/services/user-preference.service";
import {InterviewSheetsLisQueryParams} from "./interview-sheets/interfaces/interview-sheets.interface";
import {UserPreferenceStorageKeyEnum} from "./shared/interfaces";
import {environment} from "../environments/environment";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  isIframe = false;
  loginDisplay = false;
  name: string | undefined;
  accounts: AccountInfo[] = [];
  readonly onDestroy$ = new Subject<void>();

  constructor(private titleService: Title,
              private translateService: TranslateService,
              @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
              private authService: MsalService,
              private msalBroadcastService: MsalBroadcastService,
              private userService: UserService,
              private router: Router,
              private userPreferenceService: UserPreferenceService<InterviewSheetsLisQueryParams>
  ) {
    this.titleService.setTitle(this.translateService.instant('My Space - Care Services by Stimulus'));
    const entityLanguage = getEntityLanguage();
    this.translateService.setDefaultLang(localStorage.getItem('lang') || entityLanguage);
  }

  ngOnInit(): void {

    this.isIframe = window !== window.parent && !window.opener;
    this.setLoginDisplay();
    this.authService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window

    /**
     * You can subscribe to MSAL events as shown below. For more info,
     * visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/events.md
     */
    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this.onDestroy$)
      )
      .subscribe(() => {
        this.getUserProfile();
        this.setLoginDisplay();
        this.checkAndSetActiveAccount();
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) => msg.eventType === EventType.LOGOUT_SUCCESS
        ),
        takeUntil(this.onDestroy$)
      )
      .subscribe((result: EventMessage) => {
        this.setLoginDisplay();
        this.checkAndSetActiveAccount();
        const postLogoutRedirectUrl = localStorage.getItem('postLogoutRedirect');
        if(postLogoutRedirectUrl) {
          window.location.href = postLogoutRedirectUrl
          localStorage.removeItem('postLogoutRedirect')
        }
        localStorage.removeItem('userRole');
        this.userPreferenceService.remove(UserPreferenceStorageKeyEnum.interviewSheetListFilters);
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_FAILURE || msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE),
      )
      .subscribe((result: EventMessage) => {
        // Checking for the forgot password error. Learn more about B2C error codes at
        // https://learn.microsoft.com/azure/active-directory-b2c/error-codes
        if (result.error && result.error.message.indexOf('AADB2C90118') > -1) {
          let resetPasswordFlowRequest: RedirectRequest | PopupRequest = {
            authority: environment.msal.authorities.resetPassword.authority,
            scopes: [],
          };

          this.login(resetPasswordFlowRequest);
        }

      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
        ),
        takeUntil(this.onDestroy$)
      )
      .subscribe((result: EventMessage) => {
        const payload = result.payload as AuthenticationResult;
        this.authService.instance.setActiveAccount(payload.account);
      });
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
    this.name = this.authService.instance.getActiveAccount()
      ? this.authService.instance.getActiveAccount()?.username
      : 'Unknown';
    this.accounts = this.authService.instance.getAllAccounts();
  }

  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.authService.instance.getActiveAccount();

    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      let accounts = this.authService.instance.getAllAccounts();
      // add your code for handling multiple accounts here
      this.authService.instance.setActiveAccount(accounts[0]);
    }

  }

  login(userFlowRequest?: RedirectRequest | PopupRequest) {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginPopup({...this.msalGuardConfig.authRequest, ...userFlowRequest} as PopupRequest)
          .pipe(takeUntil(this.onDestroy$))
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
          });
      } else {
        this.authService.loginPopup(userFlowRequest)
          .pipe(takeUntil(this.onDestroy$))
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
          });
      }
    } else {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginRedirect({...this.msalGuardConfig.authRequest, ...userFlowRequest} as RedirectRequest);
      } else {
        this.authService.loginRedirect(userFlowRequest);
      }
    }
  }

  // unsubscribe to events when component is destroyed
  ngOnDestroy(): void {
    this.onDestroy$.next(undefined);
    this.onDestroy$.complete();
  }

  private getUserProfile() {
    const pagesWithNoRedirectRUle = window.location.pathname === '/static/legal' ||
      window.location.pathname === '/static/about' ||
      window.location.pathname === '/static/terms' ||
      window.location.pathname === '/static/protection-of-persona-data' ||
      window.location.pathname === '/static/declaration-of-accessibility' ||
      window.location.pathname === '/live-chat' ||
      window.location.pathname === '/landing' ||
      window.location.pathname === '/static/faq';
    if (!pagesWithNoRedirectRUle && this.authService.instance.getActiveAccount() !== null) {
      this.userService.getProfile().pipe(takeUntil(this.onDestroy$)).subscribe();
    }
    this.userService.userSpace.pipe(takeUntil(this.onDestroy$)).subscribe(res => {
      if (res && !pagesWithNoRedirectRUle && !window.location.pathname.includes(res)) {
        window.location.href = localStorage.getItem('redirectAfter') || `/${res}`;
        localStorage.removeItem('redirectAfter')
      }
    })
  }

}
