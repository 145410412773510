import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {FullCalendarModule} from "@fullcalendar/angular";
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from "@angular/common/http";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {FlatpickrModule} from "angularx-flatpickr";
import {HttpInterceptorInterceptor} from "./http-interceptor.interceptor";

import {b2cPolicies, loginRequest, msalConfig} from './auth-config';
import {InteractionType, IPublicClientApplication, PublicClientApplication} from "@azure/msal-browser";
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalRedirectComponent,
  MsalService
} from "@azure/msal-angular";
import {environment} from "../environments/environment";
import {Languages, UserSpaces} from "./shared/interfaces";
import {TranslateLoader, TranslateModule, TranslateStore} from "@ngx-translate/core";
import {AppRoutingModule} from "./app-routing.module";
import {RouterModule} from "@angular/router";

/**
 * Based on the url or subdomain entity we need to set specific user flows or redirect URI.
 * This way we can show a particular login screen once the user is redirected to Azure.
 */
const url = document.location.href
b2cPolicies.authorities.signUpSignIn.authority = environment.msal.authorities.signUpSignIn.authority
if (url.includes(UserSpaces.Beneficiary)) {
  loginRequest.extraQueryParameters.beneficiarySection = 'true'
  loginRequest.extraQueryParameters.image = window.location.origin + '/msal-pages/assets/beneficiary.png';
  b2cPolicies.names.signUpSignIn = environment.msal.signUpSignInBeneficiary
  b2cPolicies.authorities.signUpSignIn.authority = environment.msal.authorities.signUpSignIn.authorityBeneficiary;
  msalConfig.auth.authority = b2cPolicies.authorities.signUpSignIn.authority
}
if (url.includes(UserSpaces.BeneficiaryCompany)) {
  loginRequest.extraQueryParameters.beneficiarySection = 'true'
  loginRequest.extraQueryParameters.image = window.location.origin + '/msal-pages/assets/beneficiary.png';
  b2cPolicies.names.signUpSignIn = environment.msal.signUpSignInCompany
  b2cPolicies.authorities.signUpSignIn.authority = environment.msal.authorities.signUpSignIn.authorityBeneficiaryCompany;
  msalConfig.auth.authority = b2cPolicies.authorities.signUpSignIn.authority
}


/**
 * Here we pass the configuration parameters to create an MSAL instance.
 * For more info, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/configuration.md
 */
export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}

/**
 * Set your default interaction type for MSALGuard here. If you have any
 * additional scopes you want the user to consent upon login, add them here as well.
 */
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: loginRequest
  };
}

//TODO make sure to move FullCalendarModule to the module it will be used in
// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(environment.apiUrl + '*', loginRequest.scopes);

  return {
    interactionType: InteractionType.Popup,
    protectedResourceMap
  };
}

export const getEntityLanguage = () => {
  const getFirstSubdomain = window.location.origin.split('.')[0]
  if (getFirstSubdomain) {
    if (getFirstSubdomain.includes(Languages.Fr)) {
      return Languages.Fr
    } else if (getFirstSubdomain.includes(Languages.It)) {
      return Languages.It
    } else if (getFirstSubdomain.includes(Languages.Es)) {
      return Languages.Es
    } else {
      return Languages.Fr
    }
  } else {
    return Languages.Fr;
  }
}

@NgModule({
  declarations: [
    AppComponent,
  ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        RouterModule,
        BrowserAnimationsModule,
        NgbModule,
        FullCalendarModule,
        HttpClientModule,
        MsalModule,
        FlatpickrModule.forRoot(),
        // SharedModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
            defaultLanguage: localStorage.getItem('lang') || Languages.Fr,
            isolate: true,
        }),
    ],
  exports: [TranslateModule],
  providers: [
    TranslateStore,
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorInterceptor,
      multi: true,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule {
}
