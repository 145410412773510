import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {InteractionStatus, RedirectRequest} from "@azure/msal-browser";
import {MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService} from "@azure/msal-angular";
import {Router} from "@angular/router";
import {Subject, takeUntil} from "rxjs";
import {msalConfig} from "../../auth-config";


@Component({
  selector: 'app-login-client.ts',
  templateUrl: './login-client.component.html',
  standalone: true,
  styleUrls: ['./login-client.component.scss']
})
export class LoginClientComponent implements OnInit, OnDestroy {
  readonly onDestroy$ = new Subject<void>();
  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private router: Router
  ) {
  }

  public ngOnInit(): void {

    this.msalBroadcastService.inProgress$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((res) => {
        if(res === InteractionStatus.None && this.authService.instance.getActiveAccount() === null) {
          msalConfig.auth.redirectUri = localStorage.getItem('postLogoutRedirect') || window.location.origin
          this.authService.loginRedirect({...this.msalGuardConfig.authRequest} as RedirectRequest);
        } else {
          if(this.authService.instance.getActiveAccount() !== null) {
            let redirectPath: string;
            redirectPath = localStorage.getItem('redirectAfter') || '/'
            window.location.href = redirectPath;
          }
        }
      })
  }
  ngOnDestroy(): void {
    this.onDestroy$.next(undefined);
    this.onDestroy$.complete();
  }
}
