import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of, switchMap, tap} from "rxjs";
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {AccountCreateDTO, UserDetails, UserDto, UserRole, UserSpaces} from "../interfaces";

@Injectable({
  providedIn: 'root'
})
export class UserService {
  public userData$ = new BehaviorSubject<UserDetails | null>(null);
  public userSpace = new BehaviorSubject<UserSpaces | ''>('');

  constructor(private http: HttpClient) {
  }

  /**
   * First the authenticated user will cal a general endpoint to establish his role
   * then based on role we need to get data related to that role and set the user space to be used
   */
  public getProfile(): Observable<null | UserDetails> {
    return (this.userData$.getValue() !== null) ?
      of(this.userData$.getValue()) :
      this.http.get<UserDto>(environment.apiUrl + 'users/me').pipe(
        switchMap((user) => {
          localStorage.setItem('userRole', user.role)
          let apiUrl = 'clients/me';
          if (this.isRoleOperator(user.role)) {
            apiUrl = 'operators/me';
            this.setUSerSpaceBasedOnRole(UserSpaces.Operator);
          }
          if (this.isRoleBeneficiaryCompanyAccount(user.role)) {
            this.setUSerSpaceBasedOnRole(UserSpaces.Beneficiary);
            apiUrl = 'clients/me';
          }
          if(this.isRoleBeneficiaryPersonalAccount(user.role)) {
            this.setUSerSpaceBasedOnRole(UserSpaces.Beneficiary);
            apiUrl = 'beneficiary-account/me';
          }
          return this.http.get<UserDetails>(environment.apiUrl + apiUrl).pipe(tap((value) => {
            if (this.isRoleOperator(user.role)) {
              localStorage.setItem('operatorRef', value.data.ref)
            } else {
              localStorage.removeItem('operatorRef')
            }
            return this.userData$.next(value);
          }));
        })
      )
  }

  public isRoleOperator(role: UserRole): boolean {
    return role === UserRole.Psychologist || role === UserRole.Social_worker || role === UserRole.QWL_officer
  }
  public isRoleBeneficiaryCompanyAccount(role: UserRole): boolean {
    return role === UserRole.Company_employee || role === UserRole.Company_manager
  }
  public isRoleBeneficiaryPersonalAccount(role: UserRole): boolean {
    return role === UserRole.Beneficiary
  }
  public getUserRole(): UserRole | null {
    return this.userData$.getValue()?.data.user.role || localStorage.getItem('userRole') as UserRole
  }
  private setUSerSpaceBasedOnRole(space: UserSpaces) {
    this.userSpace.next(space)
  }

  public createBeneficiaryAccount(data: AccountCreateDTO): Observable<UserDetails> {
    return this.http.post<UserDetails>(environment.apiUrl + 'beneficiary-account', data)
  }

  public getUploadedFile(ref: string): Observable<Blob> {
    return this.http.get(
      `${environment.apiUrl}/files/${ref}/inline`,
      { responseType: 'blob' }
    );
  }
}
